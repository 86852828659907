import BrandTable from "../components/brand/brand-table";
import Navbar from "../components/navbar";


function Brand() {
    return (
        <>
        <Navbar/>
        <BrandTable/>
        </>
    )
}

export default Brand;