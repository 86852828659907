import React, { useState } from 'react';
import '../assets/styles/login.css';
import { loginApiUrl, signupApiUrl } from '../api/common-data';
import axios from 'axios';

const Signup = () => {
    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(signupApiUrl, { userName, phoneNumber, password });
            localStorage.setItem('adminProfile', JSON.stringify(response.data));
            setUserName('')
            setPhoneNumber('')
            setPassword('')
        } catch (error) {
            console.log(error);
            setError(error.message)
        }
    };

    return (
        <div className="login-container">
            <h2>Signup</h2>
            <form onSubmit={handleSubmit}>
                <label>Username:</label>
                <input type="text" value={userName} onChange={handleUserNameChange} />

                <label>Phone Number:</label>
                <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} />

                <label>Password:</label>
                <input type="password" value={password} onChange={handlePasswordChange} />

                <p>{error}</p>

                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Signup;