import AddCategory from "../components/category/add-category";
import CategoryTable from "../components/category/category-table";
import Navbar from "../components/navbar";


function Category() {
    return (
        <>
        <Navbar/>
        <CategoryTable/>
        </>
    )
}

export default Category;