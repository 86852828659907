import React, { useEffect, useState } from 'react';
import '../../assets/styles/user-table.css';
import { adminToken, getCategoryApiUrl } from '../../api/common-data';
import axios from 'axios';
import Popup from 'reactjs-popup';
import AddCategory from './add-category';
import UpdateCategory from './update-category';
import AddSubCategory from './add-subcategory';
import UpdateSubCategory from './update-subcategory';
import DeleteCategory from './delete-category';
import DeleteSubCategory from './delet-subcategory';

const CategoryTable = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [error, setError] = useState('');
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);
  const [isAddSubCategryPopupOpen, setAddSubCategoryPopupOpen] = useState(false);
  const [isUpdateSubCategryPopupOpen, setUpdateSubCategoryPopupOpen] = useState(false);
  const [isDeleteCategoryPopupOpen, setDeleteCategoryPopupOpen] = useState(false);
  const [isDeleteSubCategryPopupOpen, setDeleteSubCategoryPopupOpen] = useState(false);

  const [id, setId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categoryData, setCategoryData] = useState('');

  const openPopup = () => {
    setPopupOpen(true);
  };
  const openUpdatePopup = (id, category) => {
    setUpdatePopupOpen(true);
    setId(id)
    setCategoryData(category)
  };
  const openDeleteCategoryPopup = (id, category) => {
    setDeleteCategoryPopupOpen(true);
    setId(id)
    setCategoryData(category)
  };
  const openSubCategoryPopup = (id) => {
    setAddSubCategoryPopupOpen(true);
    setId(id)
    // setCategoryData(category)
  };
  const openUpdateSubCategoryPopup = (id, categoryId, category) => {
    setUpdateSubCategoryPopupOpen(true);
    setId(id)
    setCategoryId(categoryId)
    setCategoryData(category)
  };
  const openDeleteSubCategoryPopup = (id, categoryId, category) => {
    setDeleteSubCategoryPopupOpen(true);
    setId(id)
    setCategoryId(categoryId)
    setCategoryData(category)
  };
  const closePopup = () => {
    setPopupOpen(false);
    setUpdatePopupOpen(false);
    setAddSubCategoryPopupOpen(false)
    setUpdateSubCategoryPopupOpen(false)
    setDeleteCategoryPopupOpen(false);
    setDeleteSubCategoryPopupOpen(false);

  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.get(getCategoryApiUrl,
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      setData(response.data);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);

    } catch (error) {
      console.log(error);
      setError(error.message)
    }
  };


  return (
    <>
      <table className="user-table">
        <thead>
          <tr>
            <th><button onClick={openPopup}>Add Category</button></th>
            <th>Total Categories</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{totalPages}</td>
          </tr>
        </tbody>
      </table>
      <p>{error}</p>

      <table className="user-table">
        <thead>
          <tr>
            <th>Catagory</th>
            <th>Update</th>
            <th>Sub Catagory</th>
            <th>Add Sub Catagory</th>
          </tr>
        </thead>
        <tbody>
          {data.map((data) => (
            <tr key={data.userName}>
              <td>{data.category} </td>
              <td>
                <button onClick={() => openUpdatePopup(data.id, data.category)}>update</button>
                <button onClick={() => openDeleteCategoryPopup(data.id, data.category)}>delete</button>
              </td>
              {data.subCategory.map((data2) => (
                <tr key={data2.id}>
                  <td>{data2.subCategory}</td>
                  <td><button onClick={() => openUpdateSubCategoryPopup(data2.id, data.id, data2.subCategory)}>update</button></td>
                  <td><button onClick={() => openDeleteSubCategoryPopup(data2.id, data.id, data2.subCategory)}>delete</button></td>
                </tr> 
              ))}

              <th><button onClick={() => openSubCategoryPopup(data.id)}>Add subategory</button></th>

            </tr>
          ))}
        </tbody>
      </table>

      <Popup
        open={isPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <AddCategory />
        </div>
      </Popup>

      <Popup
        open={isUpdatePopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <UpdateCategory id={id} categoryData={categoryData} />
        </div>
      </Popup>

      <Popup
        open={isAddSubCategryPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <AddSubCategory categoryId={id} />
        </div>
      </Popup>

      <Popup
        open={isUpdateSubCategryPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <UpdateSubCategory id={id} categoryId={categoryId} subCategoryData={categoryData} />
        </div>
      </Popup>

      <Popup
        open={isDeleteCategoryPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <DeleteCategory id={id} categoryData={categoryData} />
        </div>
      </Popup>

      <Popup
        open={isDeleteSubCategryPopupOpen}
        onClose={closePopup} 
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <DeleteSubCategory id={id} categoryId={categoryId} subCategoryData={categoryData} />
        </div>
      </Popup>

    </>
  );
};

export default CategoryTable;