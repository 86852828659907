import React, { useState } from 'react';
import '../assets/styles/login.css';
import { loginApiUrl, verifyApiUrl } from '../api/common-data';
import axios from 'axios';

const Verify = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };



    const handleSubmit = async(e) => {
        e.preventDefault();
        const storedAdmin =JSON.parse(localStorage.getItem('adminProfile'));
        console.log(storedAdmin);
        try {
            const response = await axios.post(`${verifyApiUrl}/${storedAdmin.id}`, {  code });
            localStorage.setItem('adminProfile', JSON.stringify(response.data));
        } catch (error) {
            console.log(error);
            setError(error.message)
        }
    };

    return (
        <div className="login-container">
            <h2>Verify</h2>
            <form onSubmit={handleSubmit}>

                <label>Code:</label>
                <input type="text" value={code} onChange={handleCodeChange} />

                <p>{error}</p>

                <button type="submit">Verify</button>
            </form>
        </div>
    );
};

export default Verify;