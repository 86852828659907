import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/login';
import PrivateRoutes from './routes/protected-route';
import Home from './pages/home';
import Category from './pages/category';
import Brand from './pages/brand';
import VerificationCode from './pages/verification-code';
import Signup from './pages/signup';
import Verify from './pages/verify';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/verify' element={<Verify />} />

        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<Home />} />
          <Route path='/category' element={<Category />} />
          <Route path='/brand' element={<Brand />} />
          <Route path='/verificationcode' element={<VerificationCode />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
