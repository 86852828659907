import React, { useState } from 'react';
import '../assets/styles/login.css';
import { loginApiUrl } from '../api/common-data';
import axios from 'axios';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post(loginApiUrl, { phoneNumber, password });
            localStorage.setItem('adminProfile', JSON.stringify(response.data));
            setPhoneNumber('')
            setPassword('')
        } catch (error) {
            console.log(error);
            setError(error.message)
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label>Phone Number:</label>
                <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} />

                <label>Password:</label>
                <input type="password" value={password} onChange={handlePasswordChange} />

                <p>{error}</p>

                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;