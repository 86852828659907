import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  // Retrieve authentication data from local storage or any other persistent storage
  const authData = JSON.parse(localStorage.getItem("adminProfile"));
  const isAuthenticated = authData?.accessToken;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;