import React, { useState } from 'react';
import '../../assets/styles/add-category.css';
import axios from 'axios';
import {  addBrandApiUrl, addSubCategoryApiUrl, adminToken, deleteBrandApiUrl, updateBrandApiUrl } from '../../api/common-data';

const DeleteBrand = ({ id,subCategoryId,brandData }) => {
    const [brand, setBrand] = useState(brandData);
    const [error, setError] = useState('');

    const handleBrandChange = (event) => {
        setBrand(event.target.value);
    };

    const handleSave = async () => {
        try {
            const response = await axios.delete(`${deleteBrandApiUrl}/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                }
            );
            setBrand('');
        } catch (error) {
            setError(error.message)
        }
    };

    return (
        <div className="category-container">
            <div className='wrapper'>
                <p>{error}</p>
                <input
                    type="text"
                    value={brand}
                    onChange={handleBrandChange}
                    placeholder="Enter a brand"
                    className="category-input"
                />
                <button onClick={handleSave} className="category-button">Update brand</button>
            </div>
        </div>
    );
};

export default DeleteBrand;