import React, { useEffect, useState } from 'react';
import '../../assets/styles/user-table.css';
import { adminToken, verificationCodeApiUrl } from '../../api/common-data';
import axios from 'axios';
import Moment from 'react-moment';

const VerificationCodeTable = () => {
  const [verificationCode, setVerificationCode] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.get(verificationCodeApiUrl, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });

      const { verificationCode, totalCount, totalPages } = response.data;

      setVerificationCode(verificationCode);
      setTotalCount(totalCount);
      setTotalPages(totalPages);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  return (
    <>
      <table className="user-table">
        <thead>
          <tr>
            <th>Total Count</th>
            <th>Total Pages</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalCount}</td>
            <td>{totalPages}</td>
          </tr>
        </tbody>
      </table>
      <p>{error}</p>

      <table className="user-table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Phone Number</th>
            <th>Gender</th>
            <th>Active</th>
            <th>Verified</th>
            <th>Banned</th>
            <th>Login Count</th>
            <th>Verification Code</th>
            <th>Verification Code Sent</th>
            <th>Verification Code Success</th>
            <th>Created At</th>

          </tr>
        </thead>
        <tbody>
          {verificationCode.map((codeItem) => {
            const { id, verificationCode, verificationCodeSuccessful,verificationCodeSent,createdAt,user } = codeItem;
            const userId = user ? user.id : '';
            const userName = user ? user.userName : '';
            const phoneOrEmail = user ? user.phoneOrEmail : '';
            const gender = user ? user.gender : '';
            const isActive = user ? user.isActive : false;
            const isVerified = user ? user.isVerified : false;
            const isBanned = user ? user.isBanned : false;
            const loginCount = user ? user.loginCount : '';

            return (
              <tr key={id}>
                <td>{userName}</td>
                <td>{phoneOrEmail}</td>
                <td>{gender}</td>
                <td>{isActive ? 'Yes' : 'No'}</td>
                <td>{isVerified ? 'Yes' : 'No'}</td>
                <td>{isBanned ? 'Yes' : 'No'}</td>
                <td>{loginCount}</td>
                <td>{verificationCode}</td>
                <td>{verificationCodeSent ? 'Yes' : 'No'}</td>
                <td>{verificationCodeSuccessful ? 'Yes' : 'No'}</td>
                <td><Moment fromNow >{createdAt}</Moment></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default VerificationCodeTable;