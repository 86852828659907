
const getAPIUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        // Use localhost URL for development
        return 'http://localhost:5000';
    } else {
        // Use kirchat.com URL for production
        return 'https://kirchatbackendapi.kirchat.com';
    }
};
export const url = getAPIUrl();

export const apiUrlForImage = `${url}/item-images/`;
export const apiUrlForProfilePicture = `${url}/profile-pictures/`;

const adminProfile = JSON.parse(localStorage.getItem('adminProfile'))
export const adminToken = adminProfile?.accessToken

export const loginApiUrl = `${url}/admin/login`;
export const signupApiUrl = `${url}/admin/signup`;
export const verifyApiUrl = `${url}/admin/verify`;

export const addCategoryApiUrl = `${url}/category/create`;
export const updateCategoryApiUrl = `${url}/category/update`;
export const getCategoryApiUrl = `${url}/category/category`;
export const deleteCategoryApiUrl = `${url}/category/delete`;

export const addSubCategoryApiUrl = `${url}/category/subcategory/create`;
export const updateSubCategoryApiUrl = `${url}/category/subcategory/update`;
export const getSubCategoryApiUrl = `${url}/category/subcategory`;
export const deleteSubCategoryApiUrl = `${url}/category/subcategory/delete`;

export const addBrandApiUrl = `${url}/brand/create`;
export const updateBrandApiUrl = `${url}/brand/update`;
export const deleteBrandApiUrl = `${url}/brand/delete`;

/*************************admin*********** */

export const usersApiUrl = `${url}/user/users`;
export const verificationCodeApiUrl = `${url}/user/verificationcode`;