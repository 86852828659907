import React, { useState } from 'react';
import '../../assets/styles/add-category.css';
import axios from 'axios';
import {  addBrandApiUrl, addSubCategoryApiUrl, adminToken } from '../../api/common-data';

const AddBrand = ({ subCategoryId }) => {
    const [brand, setBrand] = useState('');
    const [error, setError] = useState('');

    const handleBrandChange = (event) => {
        setBrand(event.target.value);
    };
    console.log(adminToken);

    const handleSave = async () => {
        try {
            const response = await axios.post(addBrandApiUrl, {subCategoryId, brand },
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                }
            );
            setBrand('');
        } catch (error) {
            setError(error.message)
        }
    };

    return (
        <div className="category-container">
            <div className='wrapper'>
                <p>{error}</p>
                <input
                    type="text"
                    value={brand}
                    onChange={handleBrandChange}
                    placeholder="Enter a brand"
                    className="category-input"
                />
                <button onClick={handleSave} className="category-button">Add brand</button>
            </div>
        </div>
    );
};

export default AddBrand;