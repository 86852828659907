import React from 'react';
import '../assets/styles/navbar.css'; // Import the CSS file for styling

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a href="/" className="nav-link">Users</a>
        </li>
        <li className="nav-item">
          <a href="/verificationcode" className="nav-link">Verification Code</a>
        </li>
        <li className="nav-item">
          <a href="/category" className="nav-link">Category</a>
        </li>
        <li className="nav-item">
          <a href="/brand" className="nav-link">Brand</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;