import React, { useEffect, useState } from 'react';
import '../../assets/styles/user-table.css';
import { adminToken, getCategoryApiUrl } from '../../api/common-data';
import axios from 'axios';
import Popup from 'reactjs-popup';
import AddBrand from './add-brand';
import UpdateBrand from './update-brand';
import DeleteBrand from './delete-brand';


const BrandTable = () => {
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [error, setError] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isUpdateBrandPopupOpen, setUpdateBrandPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeleteBrandPopupOpen] = useState(false);

    const [id, setId] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [brandData, setBrandData] = useState('');

    const openAddBrandPopup = (subCategoryId) => {
        setSubCategoryId(subCategoryId)
        setPopupOpen(true)
    };
    const openUpdateBrandPopup = (id, subCategoryId, brand) => {
        setId(id)
        setSubCategoryId(subCategoryId)
        setUpdateBrandPopupOpen(true)
        setBrandData(brand)
    };
    const openDeleteBrandPopup = (id, subCategoryId, brand) => {
        setDeleteBrandPopupOpen(true)
        setId(id)
        setSubCategoryId(subCategoryId)
        setBrandData(brand)
    };

    const closePopup = () => {
        setPopupOpen(false);
        setUpdateBrandPopupOpen(false);
        setDeleteBrandPopupOpen(false)
      

    };
    useEffect(() => {
        handleSubmit();
    }, []);

    const handleSubmit = async () => {
        try {
            const response = await axios.get(getCategoryApiUrl,
                {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                }
            );
            setData(response.data);
            setTotalCount(response.data.totalCount);
            setTotalPages(response.data.totalPages);

        } catch (error) {
            console.log(error);
            setError(error.message)
        }
    };


    return (
        <>
            <table className="user-table">
                <thead>
                    <tr>
                        <th><button>Add Category</button></th>
                        <th>Total Categories</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>{totalPages}</td>
                    </tr>
                </tbody>
            </table>
            <p>{error}</p>

            <table className="user-table">
                <thead>
                    <tr>
                        <th>Catagory</th>
                        <th>Sub Catagory</th>
                        <th>Brand</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((data) => (
                        <tr key={data.userName}>
                            <td>{data.category} </td>

                            {data.subCategory.map((data2) => (
                                <tr key={data2.id}>
                                    <td>{data2.subCategory}</td>

                                    {data2.brand.map((data3) => (
                                        <tr key={data3.id}>
                                            <td>{data3.brand}</td>
                                            <td><button onClick={() => openUpdateBrandPopup(data3.id, data2.id, data3.brand)}>update</button></td>
                                            <td><button onClick={() => openDeleteBrandPopup(data3.id, data2.id, data3.brand)}>delete</button></td>

                                        </tr>
                                    ))}
                                    <th><button onClick={() => openAddBrandPopup(data2.id)}>Add Brand</button></th>

                                </tr>
                            ))}


                        </tr>
                    ))}
                </tbody>
            </table>

            <Popup
                open={isPopupOpen}
                onClose={closePopup}
                modal
                centered
            >
                <div className='popup-content'>
                    <button className='popup-close-button' onClick={closePopup} >
                        &times;
                    </button>
                    <AddBrand subCategoryId={subCategoryId} />
                </div>
            </Popup>

            <Popup
                open={isUpdateBrandPopupOpen}
                onClose={closePopup}
                modal
                centered
            >
                <div className='popup-content'>
                    <button className='popup-close-button' onClick={closePopup} >
                        &times;
                    </button>
                    <UpdateBrand id={id} subCategoryId={subCategoryId} brandData={brandData} />
                </div>
            </Popup>

            <Popup
                open={isDeletePopupOpen}
                onClose={closePopup}
                modal
                centered
            >
                <div className='popup-content'>
                    <button className='popup-close-button' onClick={closePopup} >
                        &times;
                    </button>
                    <DeleteBrand id={id} subCategoryId={subCategoryId} brandData={brandData} />
                </div>
            </Popup>
            {/*}
      <Popup
        open={isUpdateSubCategryPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <UpdateSubCategory id={id} categoryId={categoryId} subCategoryData={categoryData} />
        </div>
      </Popup>

      <Popup
        open={isDeleteCategoryPopupOpen}
        onClose={closePopup}
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <DeleteCategory id={id} categoryData={categoryData} />
        </div>
      </Popup>

      <Popup
        open={isDeleteSubCategryPopupOpen}
        onClose={closePopup} 
        modal
        centered
      >
        <div className='popup-content'>
          <button className='popup-close-button' onClick={closePopup} >
            &times;
          </button>
          <DeleteSubCategory id={id} categoryId={categoryId} subCategoryData={categoryData} />
        </div>
              </Popup>*/}

        </>
    );
};

export default BrandTable;