import Navbar from "../components/navbar";
import VerificationCodeTable from "../components/verification-code/verification-code-table";


function VerificationCode() {
    return (
        <>
        <Navbar/>
            <VerificationCodeTable/>
        </>
    )
}

export default VerificationCode;