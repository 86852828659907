import Navbar from "../components/navbar";
import UserTable from "../components/user-table";


function Home() {
    return (
        <>
        <Navbar/>
            <UserTable />
        </>
    )
}

export default Home;