import React, { useEffect, useState } from 'react';
import '../assets/styles/user-table.css';
import { adminToken, usersApiUrl } from '../api/common-data';
import axios from 'axios';

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      /* try {
         const response = await fetch('https://api.example.com/users');
         const { data } = await response.json();
         setData(data);
       } catch (error) {
         console.error('Error fetching user data:', error);
       }*/
    };

    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.get(usersApiUrl,
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      setUsers(response.data.users);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);

    } catch (error) {
      console.log(error);
      setError(error.message)
    }
  };





  return (

    <>
      <table className="user-table">
        <thead>
          <tr>
            <th>Total Count</th>
            <th>Total Pages</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalCount}</td>
            <td>{totalPages}</td>
          </tr>
        </tbody>
      </table>
      <p>{error}</p>

      <table className="user-table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Phone or Email</th>
            <th>Gender</th>
            <th>Active</th>
            <th>Verified</th>
            <th>Banned</th>
            <th>Login Count</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.userName}>
              <td>{user.userName}</td>
              <td>{user.phoneOrEmail}</td>
              <td>{user.gender}</td>
              <td>{user.isActive ? 'Yes' : 'No'}</td>
              <td>{user.isVerified ? 'Yes' : 'No'}</td>
              <td>{user.isBanned ? 'Yes' : 'No'}</td>
              <td>{user.loginCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UserTable;